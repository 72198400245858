import {graphql, PageProps} from 'gatsby';
import {BlogPostTemplateQuery} from './__generated__/BlogPostTemplateQuery';
import {Layout} from '../components/Layout';
import React, {FunctionComponent} from 'react';
import {PageWrapper, RichText} from '../styles/page';
import PageWidth from '../components/PageWidth';
import {getLocalImageFromFragment} from '../utils';
import SEO from '../components/SEO';
import renderRichText from '../utils/rich-text-renderer';
import Img from 'gatsby-image';
import {BlogContent} from '../components/BlogContent';
import {BlogHeader, BlogFooter} from '@focusritegroup/web-ui';
import Colours from '../constants/colours';

export const blogPostTemplateQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    contentfulAmpifyWebBlogPost(contentful_id: {eq: $id}) {
      pageTitle
      metaKeywords
      updatedAt
      headerImage {
        ...AmpifyHeaderImage
      }
      pageContent {
        raw
        references {
          ... on ContentfulAsset {
            ...AssetImage
          }
          ... on ContentfulAmpifyWebSoundcloud {
            ...SoundCloudLink
          }
          ... on ContentfulAmpifyWebVimeo {
            ...VimeoLink
          }
          ... on ContentfulEmbeddableUrl {
            ...EmbeddableUrl
          }
        }
      }
    }
  }
`;

const BlogPostTemplate: FunctionComponent<PageProps<BlogPostTemplateQuery>> = (
  props
) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const blogPost = props.data.contentfulAmpifyWebBlogPost;
  return (
    <Layout>
      <SEO
        title={blogPost.pageTitle}
        keywords={blogPost.metaKeywords.split(',')}
      />
      <PageWrapper>
        <PageWidth wide>
          <BlogHeader
            image={() => (
              <Img
                fluid={getLocalImageFromFragment(blogPost.headerImage)}
                style={{width: '100%', height: '100%'}}
              />
            )}
            title={blogPost.pageTitle}
            publishDate={new Date(blogPost.updatedAt)}
            publishDateColor={Colours.black}
          />
        </PageWidth>
        <PageWidth>
          <BlogContent>
            <RichText>{renderRichText(blogPost.pageContent)}</RichText>
          </BlogContent>
          <BlogFooter shareUrl={url} />
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export default BlogPostTemplate;

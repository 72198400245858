import React from 'react';
import {BlogContentWrapper} from './BlogContent.styles';

export const BlogContent: React.FunctionComponent = (props) => {
  return (
    <BlogContentWrapper data-test-id="blog-content">
      {props.children}
    </BlogContentWrapper>
  );
};

import styled from 'styled-components';

export const BlogContentWrapper = styled.div`
  margin-bottom: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
`;
